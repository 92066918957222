import React from "react";
import client from "lib/ApolloClient";
import DataList from "components/list/DataList";
import BookmarkDisplayComp from "components/cust/BookmarkDisplayComp";
import GraphqlFragment from "lib/GraphqlFragment";
import { ApolloProvider, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import qs from "query-string";
import navigate from "lib/navigate";
import BookmarkQueryGroupComp from "components/cust/BookmarkQueryGroupComp";
import cateAPI from "lib/cateAPI";
import { inject, observer } from "mobx-react";

import BookmarkListModal from "./BookmarkListModal";
import ShareExportModal from "../common/ShareExportModal";
import ReactHtmlParser from "react-html-parser";

const getBookmarkList = gql`
  query getBookmarkList($form: BookmarkForm) {
    getBookmarkList(Input: $form) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

const deleteBookmark = gql`
  mutation deleteBookmark($ids: [Int]) {
    deleteBookmark(ids: $ids) {
      success
      message
    }
  }
`;

@inject("appStore")
@observer
class BookmarkListComp extends React.Component {
  constructor(props) {
    super(props);
    let { resourceConfig } = props.appStore;
    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";
    this.state = {
      auth: props.readerStore.auth,
      dbID: "",
      resourceType: defaultResourceType,
      total: 0,
      checkedData: [],
      checkedDataString: [],
      ids: [],
      refetch: null,
      modalVisible: false,
      exportModalVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      JSON.stringify(props.searchForm) !== JSON.stringify(this.props.searchForm)
    ) {
      this.setState({ ids: [] });
    }
  }

  @autobind
  handleShowSort(e) {
    e.preventDefault();
    this.setState({ showSort: !this.state.showSort });
  }

  @autobind
  onQueryCompleted(data) {
    let { info, list } = data.getBookmarkList;
    let { total } = info;
    let { values } = list;

    let ids = [];
    values.map((value) => {
      let { ref } = value;
      ref.map((v) => {
        if (v.key === "id") {
          ids.push(parseInt(v.value));
        }
        return "";
      });
      return "";
    });

    var update = false;
    if (ids.length !== this.state.ids.length) update = true;
    for (var i = 0; i < this.state.ids.length; i++) {
      if (this.state.ids[i] !== ids[i]) {
        update = true;
        break;
      }
    }
    if (update) this.setState({ ids: ids });

    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  }

  @autobind
  handleTagAll(e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      if (!this.state.isAllTag) {
        cateAPI
          .addListCate(this.state.ids, this.props.resourceType)
          .then((data) => {
            if (
              this.state.refetch !== null &&
              this.state.refetch !== undefined
            ) {
              this.state.refetch();
            }
          });
      } else {
        cateAPI
          .delListCate(this.state.ids, this.props.resourceType)
          .then((data) => {
            if (
              this.state.refetch !== null &&
              this.state.refetch !== undefined
            ) {
              this.state.refetch();
            }
          });
      }
    } else {
      this.props.appStore.openLogin();
    }
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  changeSortType(sort, order, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.sort = sort;
    params.order = order;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    this.setState({ showSort: false });
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  changePageType(pageType, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = {};
    params.resourceType = search.resourceType;
    params.pageType = pageType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  changePageTypeWithClassSN(sn, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.resourceType = search.resourceType;
    params.pageType = "class";
    params.par = sn;
    params.sc = "1";
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  addChechedId(e) {
    var id = parseInt(e.target.value);
    var c = this.state.checkedData;
    var cs = this.state.checkedDataString;
    if (e.target.checked) {
      c.push(id);
      cs.push(id.toString());
    } else {
      var index = c.indexOf(id);
      if (index !== -1) {
        c.splice(index, 1);
        cs.splice(cs.indexOf(id.toString()), 1);
      }
    }

    this.setState({ checkedData: c, checkedDataString: cs });
  }

  render() {
    let { searchForm, t } = this.props;
    let { showSort, showFilter } = this.state;

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <div className={showFilter ? "filter_search show_up" : "filter_search"}>
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={this.props.t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <BookmarkQueryGroupComp
            ref={(c) => (this.queryGroup = c)}
            {...this.props}
            changeShowFilter={this.changeShowFilter}
            showFilter={showFilter}
          />
        </div>
        <div className="search_result">
          <a
            className="accesskey"
            href="javascript:;"
            id="aC"
            accessKey="C"
            title={this.props.t("jumperrwd.common.mainContenctArea")}>
            :::
          </a>
          <h2>{t("jumperrwd.common.collectionList")}</h2>
          <div className="function_panel">
            <div className="result_amount">
              <input
                type="checkbox"
                tilte="選擇全部"
                checked={
                  this.state.ids.length === this.state.checkedData.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    var cs = [];
                    this.state.ids.map((id) => {
                      cs.concat(id.toString());
                      return "";
                    });
                    this.setState({
                      checkedData: this.state.ids,
                      checkedDataString: cs,
                    });
                  } else this.setState({ checkedData: [] });
                }}
              />
              {t("jumperrwd.common.searchResult")}：
              <div className="total">
                {ReactHtmlParser(
                  t("jumperrwd.list.totalAmount", {
                    total: this.state.total,
                  })
                )}
              </div>
              <div className="filter_num">
                {ReactHtmlParser(
                  t("jumperrwd.list.checkedAmount", {
                    total: this.state.checkedData.length,
                  })
                )}
              </div>
            </div>
            <div className="function_block">
              <div className="btn_grp">
                <button
                  type="button"
                  title={t("jumper.common.addtag")}
                  tabIndex="0"
                  onClick={() => {
                    if (this.state.checkedData.length > 0) {
                      this.setState({ modalVisible: true });
                    } else {
                      alert(t("jumperrwd.cust.pleaseCheckOne"));
                    }
                  }}>
                  <i className="i_tag"></i>
                  <span>{t("jumper.common.addtag")}</span>
                </button>
                <button
                  type="button"
                  title={t("jumperrwd.common.shareAndExport")}
                  tabIndex="0"
                  onClick={() => {
                    if (this.state.checkedData.length > 0) {
                      this.setState({ exportModalVisible: true });
                    } else {
                      alert(t("jumperrwd.cust.pleaseCheckOne"));
                    }
                  }}>
                  <i className="i_share"></i>
                  <span>{t("jumperrwd.common.shareAndExport")}</span>
                </button>
                <Mutation mutation={deleteBookmark}>
                  {(deleteBookmark) => (
                    <button
                      type="button"
                      title={t("jumperrwd.common.cancelCollection")}
                      tabIndex="0"
                      onClick={() => {
                        if (this.state.checkedData.length > 0) {
                          deleteBookmark({
                            variables: { ids: this.state.checkedData },
                          }).then((res) => {
                            if (res.data.deleteBookmark.success) {
                              alert(t("jumperrwd.common.deleteSuccess"));
                              this.state.refetch();
                              if (this.queryGroup)
                                this.queryGroup.state.refetch();
                              this.setState({
                                checkedData: [],
                                checkedDataString: [],
                              });
                            } else {
                              alert(
                                t("jumperrwd.common.deleteFailed") +
                                  " " +
                                  res.data.deleteBookmark.message
                              );
                            }
                          });
                        } else {
                          alert(t("jumperrwd.cust.pleaseCheckOne"));
                        }
                      }}>
                      <i className="i_bookmark_no"></i>
                      <span>{t("jumperrwd.common.cancelCollection")}</span>
                    </button>
                  )}
                </Mutation>
              </div>
              <div className="option">
                <div
                  className="name"
                  tabIndex="0"
                  onClick={this.handleShowSort.bind(this)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleShowSort(e);
                    }
                  }}>
                  <a>
                    {t("jumperrwd.common.sortType")}
                    {searchForm.sort !== undefined ? (
                      <>
                        :
                        {(searchForm.sort === "cretime"
                          ? t`jumper.common.addtime`
                          : t`jumper.common.table.title`) +
                          ` _ ` +
                          (searchForm.order === "asc"
                            ? t`hyint.common.table.asc`
                            : t`hyint.common.table.descendent`)}
                      </>
                    ) : (
                      <>
                        :
                        {t`jumper.common.addtime` +
                          ` - ` +
                          t`hyint.common.table.descendent`}
                      </>
                    )}
                  </a>
                </div>
                {showSort && (
                  <div className="option_list">
                    <ul>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(
                          this,
                          "cretime",
                          "desc"
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("cretime", "desc", e);
                          }
                        }}>
                        <a>
                          {t`jumper.common.addtime` +
                            ` - ` +
                            t`hyint.common.table.descendent`}
                        </a>
                      </li>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(
                          this,
                          "cretime",
                          "asc"
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("cretime", "asc", e);
                          }
                        }}>
                        <a>
                          {t("jumper.common.addtime") +
                            ` - ` +
                            t`hyint.common.table.asc`}
                        </a>
                      </li>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(
                          this,
                          "title",
                          "desc"
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("title", "desc", e);
                          }
                        }}>
                        <a>
                          {t("jumper.common.table.title") +
                            ` - ` +
                            t`hyint.common.table.descendent`}
                        </a>
                      </li>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(this, "title", "asc")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("title", "asc", e);
                          }
                        }}>
                        <a>
                          {t("jumper.common.table.title") +
                            ` - ` +
                            t`hyint.common.table.asc`}
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <button
                type="button"
                tabIndex="0"
                className="filter_search_btn"
                onClick={this.changeShowFilter.bind(this, true)}>
                <i className="i_filter"></i>
              </button>
            </div>
          </div>
          <div className="list_all">
            <DataList
              query={getBookmarkList}
              variables={{
                form: {
                  custKey: this.props.searchForm.custKey,
                  cate_id: parseInt(this.props.searchForm.cate_id),
                  limit: this.props.searchForm.limit,
                  pageNo: this.props.searchForm.pageNo,
                  sort: this.props.searchForm.sort,
                  order: this.props.searchForm.order,
                },
              }}
              fetchPolicy={"network-only"}
              displayComp={BookmarkDisplayComp}
              pagination={"FixedPagination"}
              getRefetch={this.getRefetch}
              onQueryCompleted={this.onQueryCompleted}
              showNoData={true}
              refetchQueryGroup={
                this.queryGroup ? this.queryGroup.state.refetch : null
              }
              handleClick={this.addChechedId}
              isChecked={(id) => {
                if (this.state.checkedData.includes(id)) return true;
                else return false;
              }}
            />
            <BookmarkListModal
              visible={this.state.modalVisible}
              cate={""}
              ids={this.state.checkedData}
              refetch={this.state.refetch}
              close={() => this.setState({ modalVisible: false })}
            />
            <ShareExportModal
              visible={this.state.exportModalVisible}
              ids={this.state.checkedDataString}
              pageType="bookmark"
              resourceType=""
              close={() => this.setState({ exportModalVisible: false })}
            />
          </div>
        </div>
      </ApolloProvider>
    );
  }
}

export default BookmarkListComp;
