import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import navigate from "lib/navigate";

import { arrangeData } from "components/list/action/action-util";
import View from "components/list/view/View";

import BookmarkListModal from "./BookmarkListModal";
import ShareExportModal from "../common/ShareExportModal";
import Link from "lib/Link";

const deleteBookmark = gql`
  mutation deleteBookmark($ids: [Int]) {
    deleteBookmark(ids: $ids) {
      success
      message
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

const encodeUrl = (Url) => {
  Url = decodeURIComponent(Url);
  let pos = Url.indexOf("url=");
  if (pos > -1) {
    let tempUrl = Url.substring(pos + 4);
    let urls = tempUrl.split("?");
    let url = urls[0];
    let queryString = "";
    if (urls.length > 1) {
      queryString = "?" + urls[1];
    }
    let { query } = qs.parseUrl(queryString);
    query.title = encodeURIComponent(query.title);
    Url = `${Url.substring(0, pos + 4)}${[url, qs.stringify(query)].join("?")}`;
  }
  return Url;
};

class BookmarkDisplayComp extends View {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      exportModalVisible: false,
    };
  }

  unescape = (s) => {
    return s
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&#39;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/<[^>]*>/g, "");
  };

  reSearch = (keyword) => {
    let params = qs.parseUrl(window.location.href).query;
    delete params.cate_id;
    delete params.custKey;
    delete params.pageType;
    params.searchInput = keyword;
    params.searchField = "TI";
    window.scrollTo(0, 0);
    navigate([window.location.uri, qs.stringify(params)].join("?"));
  };

  render() {
    let { data, rowStyle, serialNo, t, refetch, refetchQueryGroup } =
      this.props;

    const arrangedData = arrangeData(data);

    let {
      id,
      opaclink,
      an,
      dbID,
      pLink,
      key,
      photo = "",
      authors,
      subjects,
      source,
      ebID,
      title,
      dbLabel,
      cate,
    } = arrangedData;

    if (photo === "") {
      photo = "/file/images/icon_book.svg";
    }

    return (
      <>
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">
            <input
              title={t("jumperrwd.common.selectBookmark")}
              type="checkbox"
              value={id}
              checked={this.props.isChecked(parseInt(id))}
              onChange={this.props.handleClick}
            />
            {serialNo}
          </div>
          <div className="function_btn">
            <button
              type="button"
              tabIndex="0"
              title={t("jumper.common.addtag")}
              onClick={() => this.setState({ modalVisible: true })}>
              <i className="i_tag"></i>
              <span>{t("jumper.common.addtag")}</span>
            </button>
            <button
              type="button"
              tabIndex="0"
              title={t("jumperrwd.common.shareAndExport")}
              onClick={() => this.setState({ exportModalVisible: true })}>
              <i className="i_share"></i>
              <span>{t("jumperrwd.common.shareAndExport")}</span>
            </button>
            <Mutation mutation={deleteBookmark}>
              {(deleteBookmark) => (
                <button
                  type="button"
                  tabIndex="0"
                  title={t("jumperrwd.common.cancelCollection")}
                  onClick={() => {
                    deleteBookmark({ variables: { ids: [id] } }).then((res) => {
                      if (res.data.deleteBookmark.success) {
                        alert(t("jumperrwd.common.deleteSuccess"));
                        refetch();
                        refetchQueryGroup();
                      } else {
                        alert(
                          t("jumperrwd.common.deleteFailed") +
                            " " +
                            res.data.deleteBookmark.message
                        );
                      }
                    });
                  }}>
                  <i className="i_bookmark_no"></i>
                  <span>{t("jumperrwd.common.cancelCollection")}</span>
                </button>
              )}
            </Mutation>
          </div>
          <div className="sort_icon">
            <div className="pic">
              <img
                src={photo}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
            <div className="sort_name">{t("jumper.type.book")}</div>
          </div>
          <div className="list_data">
            <h3>
              <Link
                href={
                  opaclink !== ""
                    ? encodeUrl(opaclink)
                    : key.includes("websearch") || key.includes("collection")
                    ? "/sendURLApiV3?" +
                      (an !== "" ? "mode=link" : "linktype=1&hyintid=" + dbID) +
                      "&url=" +
                      encodeURIComponent(decodeURIComponent(pLink)) +
                      "&title=" +
                      ReactHtmlParser(title)
                    : encodeUrl(pLink)
                }
                title={t("jumperrwd.common.openNewWindow")}
                target="_blank"
                rel="noreferrer">
                {ReactHtmlParser(title)}
              </Link>
            </h3>
            <ul>
              {authors !== "" && (
                <li>
                  <div className="title">
                    {t("hyint.common.table.author")}：
                  </div>
                  <div>
                    {authors.indexOf("^n") > -1 ? (
                      authors.split("^n").map((author, i) => {
                        return (
                          <>
                            {author !== "" && (
                              <li key={"author" + i}>{author}</li>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <>{authors}</>
                    )}
                  </div>
                </li>
              )}
              {subjects !== "" && (
                <li>
                  <div className="title">
                    {t("jumper.common.subjectkeyword")}：
                  </div>
                  <ul className="keyword">
                    {subjects.indexOf("^n") > -1 ? (
                      subjects.split("^n").map((subject, i) => {
                        return (
                          <>
                            {subject !== "" && (
                              <li key={"subject" + i}>{subject}</li>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <>
                        {ReactHtmlParser(
                          htmlDecode(
                            subjects.replace(new RegExp("^n", "gm"), " ")
                          )
                        )}
                      </>
                    )}
                  </ul>
                </li>
              )}
              {source !== "" && (
                <li>
                  <div className="title">
                    {t("hyint.admin.common.table.source")}：
                  </div>
                  {this.unescape(source)}
                </li>
              )}
              <li>
                <div className="title">
                  {t("hyint.admin.common.table.db")}：
                </div>
                {ebID !== "" ? (
                  <Link href={"/sendURLApiV3?dbid=" + ebID}>{dbLabel}</Link>
                ) : (
                  <>{dbLabel}</>
                )}
              </li>
              <li>
                <div className="title">{t("jumper.common.tag")}：</div>
                <div className="tag_grp">
                  {cate !== "" &&
                    cate.split(",").map((c, i) => {
                      return (
                        <Link
                          title={"使用" + c.split("::")[1] + "關鍵字"}
                          key={"cate" + i}
                          href={
                            "/custList?pageType=bookmark&cate_id=" +
                            c.split("::")[0]
                          }
                          className="tag">
                          {c.split("::")[1]}
                        </Link>
                      );
                    })}
                  <button
                    type="button"
                    className="edit_tag"
                    tabIndex="0"
                    onClick={() => this.setState({ modalVisible: true })}>
                    <i className="i_edit"></i>
                    {t("jumper.common.fixtag")}
                  </button>
                </div>
              </li>
            </ul>
            {/*<button type="button" className="btn_normal">
              詳目
            </button>
            <button type="button" className="btn_normal">
              <img src="images/full_text_finder.png" alt="" />
              Full Text Finder
            </button>*/}
          </div>
        </div>
        <BookmarkListModal
          visible={this.state.modalVisible}
          cate={cate}
          ids={[parseInt(id)]}
          refetch={refetch}
          close={() => this.setState({ modalVisible: false })}
        />
        <ShareExportModal
          visible={this.state.exportModalVisible}
          ids={[id]}
          pageType="bookmark"
          resourceType=""
          close={() => this.setState({ exportModalVisible: false })}
        />
      </>
    );
  }
}

BookmarkDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

BookmarkDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default BookmarkDisplayComp;
