import React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import ReactLoading from "react-loading";
import { withI18next } from "lib/withI18next";

const getBookmarkCateList = gql`
  query getBookmarkCateList {
    getBookmarkCateList {
      allbm_count
      allunbm_count
      cate_list {
        id
        cate_name
        count
      }
    }
  }
`;

const addBookmark2Cate = gql`
  mutation addBookmark2Cate($ids: [Int], $cate_id: Int) {
    addBookmark2Cate(ids: $ids, cate_id: $cate_id) {
      success
      message
    }
  }
`;

const removeBookmark2Cate = gql`
  mutation removeBookmark2Cate($id: Int, $cate_id: Int) {
    removeBookmark2Cate(id: $id, cate_id: $cate_id) {
      success
      message
    }
  }
`;

const addBookmarkCate = gql`
  mutation addBookmarkCate($name: String, $ids: [Int]) {
    addBookmarkCate(name: $name, ids: $ids) {
      success
      message
    }
  }
`;

@withI18next(["common"])
class BookmarkListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: props.visible ? "block" : "none",
      cate: props.cate,
      ids: props.ids,
      newCate: "",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }

    if (props.cate !== this.props.cate) {
      this.setState({ cate: props.cate });
    }
  }

  render() {
    let { t } = this.props;
    return (
      <div
        className="personal_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" onClick={this.props.close}></div>
        <div className="personalblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}></div>
          <h3>{t("jumper.common.addtag")}</h3>
          <Query query={getBookmarkCateList}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <center>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </center>
                );
              if (error) return `Error!${error.message}`;

              if (data.getBookmarkCateList) {
                var added = [];
                var other = [];
                data.getBookmarkCateList.cate_list.map((c) => {
                  var add = false;
                  if (this.state.ids.length === 1) {
                    this.state.cate.split(",").map((c2) => {
                      if (c.id === c2.split("::")[0]) {
                        added.push(c);
                        add = true;
                      }
                      return "";
                    });
                  }
                  if (!add) {
                    other.push(c);
                  }
                  return "";
                });
                return (
                  <>
                    <div className="mytags added_tag">
                      <h4>{t("jumperrwd.common.addedTag")}</h4>
                      <ul>
                        {added.map((c) => {
                          return (
                            <li>
                              {c.cate_name}
                              <span>({c.count})</span>
                              <Mutation mutation={removeBookmark2Cate}>
                                {(removeBookmark2Cate) => (
                                  <button
                                    type="button"
                                    className="del_btn"
                                    tabIndex="0"
                                    onClick={() => {
                                      removeBookmark2Cate({
                                        variables: {
                                          id: this.state.ids[0],
                                          cate_id: c.id,
                                        },
                                      }).then((res) => {
                                        if (
                                          res.data.removeBookmark2Cate.success
                                        ) {
                                          alert(
                                            t("jumperrwd.common.deleteSuccess")
                                          );
                                          this.props.refetch();
                                          refetch();
                                        } else {
                                          alert(
                                            t("jumperrwd.common.deleteFailed")
                                          );
                                        }
                                      });
                                    }}>
                                    {t("jumperrwd.common.delete")}
                                  </button>
                                )}
                              </Mutation>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="mytags other_tag">
                      <h4>{t("jumperrwd.common.otherTag")}</h4>
                      <ul>
                        {other.map((c) => {
                          return (
                            <li>
                              {c.cate_name}
                              <span>({c.count})</span>
                              <Mutation mutation={addBookmark2Cate}>
                                {(addBookmark2Cate) => (
                                  <button
                                    type="button"
                                    className="add_btn"
                                    tabIndex="0"
                                    onClick={() => {
                                      addBookmark2Cate({
                                        variables: {
                                          ids: this.state.ids,
                                          cate_id: c.id,
                                        },
                                      }).then((res) => {
                                        if (res.data.addBookmark2Cate.success) {
                                          alert(
                                            t("jumperrwd.common.addSuccess")
                                          );
                                          this.props.refetch();
                                          refetch();
                                          this.props.close();
                                        } else {
                                          alert(
                                            t("jumperrwd.common.addFailed")
                                          );
                                        }
                                      });
                                    }}>
                                    {t("jumper.common.table.add")}
                                  </button>
                                )}
                              </Mutation>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <hr />
                    <div className="newtag_input">
                      <form action="">
                        <label htmlFor="tagName">
                          {t("jumperrwd.tag.addTagName")}
                        </label>
                        <input
                          type="text"
                          id="newCate"
                          title={t("jumperrwd.common.enterTagName")}
                          placeholder={t("jumperrwd.tag.enterTagName")}
                          value={this.state.newCate}
                          onChange={(e) =>
                            this.setState({ newCate: e.target.value })
                          }
                        />
                        <div className="btn_grp_right">
                          <button
                            type="reset"
                            tabIndex="0"
                            onClick={this.props.close}>
                            {t("jumperrwd.common.cancel")}
                          </button>
                          <Mutation mutation={addBookmarkCate}>
                            {(addBookmarkCate) => (
                              <button
                                type="submit"
                                disabled={this.state.newCate === ""}
                                tabIndex="0"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addBookmarkCate({
                                    variables: {
                                      name: this.state.newCate,
                                      ids: this.state.ids,
                                    },
                                  }).then((res) => {
                                    if (res.data.addBookmarkCate.success) {
                                      alert(t("jumperrwd.common.addSuccess"));
                                      this.props.refetch();
                                      refetch();
                                      this.props.close();
                                      this.setState({ newCate: "" });
                                    } else {
                                      alert(
                                        t("jumperrwd.common.addFailed") +
                                          " " +
                                          res.data.addBookmarkCate.message
                                      );
                                    }
                                  });
                                }}>
                                {t("hyint.admin.common.add")}
                              </button>
                            )}
                          </Mutation>
                        </div>
                      </form>
                    </div>
                  </>
                );
              }
              return "";
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default BookmarkListModal;
