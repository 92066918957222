import React from "react";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import navigate from "lib/navigate";
import qs from "query-string";
import { Interpolate } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const getEbookHistoryQueryGroup = gql`
  query getEbookHistoryQueryGroup($searchForm: EbookHistoryForm) {
    getEbookHistoryQueryGroup(Input: $searchForm) {
      total
      queryGroupList {
        hasLimit
        dbID
        sourceName
        sourceEnName
        count
        remainCount
      }
    }
  }
`;

@inject("appStore", "readerStore")
@observer
class EbookHistoryQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
    };
  }

  @autobind
  changeDBID(dbID) {
    let { location, search } = this.props;
    let params = { ...search };
    params.dbID = dbID;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.props.showFilter) {
      this.props.changeShowFilter(false);
    }
  }

  render() {
    let { t, i18n, searchForm } = this.props;
    let { language } = i18n;
    let theListType = searchForm.listType || "lend-current";
    let listShowType = "lendCurrent";
    if (theListType === "lend-current") {
      listShowType = "lendCurrent";
    } else if (theListType === "lend-history") {
      listShowType = "lendHistory";
    } else if (theListType === "reserve-current") {
      listShowType = "reserveCurrent";
    } else if (theListType === "reserve-history") {
      listShowType = "reserveHistory";
    }
    let theDBID = searchForm.dbID || "";
    return (
      <>
        <a
          className="accesskey"
          href="javascript:;"
          id="aL"
          accessKey="L"
          title={this.props.t("jumperrwd.common.leftSideArea")}>
          :::
        </a>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getEbookHistoryQueryGroup}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                listType: theListType,
              },
            }}>
            {({ loading, data, refetch, error }) => {
              if (error) return "";
              if (loading) return "Loading...";
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch }, () => {
                  if (this.props.getRefetch) {
                    this.props.getRefetch(refetch);
                  }
                });
              }

              if (
                data.getEbookHistoryQueryGroup !== null &&
                data.getEbookHistoryQueryGroup !== undefined
              ) {
                let { total, queryGroupList } = data.getEbookHistoryQueryGroup;
                return (
                  <>
                    <h2>{t("jumperrwd.common.ebookHistory")}</h2>
                    <div className="sort_list">
                      <ul>
                        <li>
                          <div className="accordion_content">
                            <ul>
                              <li className={theDBID === "" ? "here" : ""}>
                                <a
                                  tabIndex="0"
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.changeDBID("");
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.changeDBID("");
                                    }
                                  }}>
                                  <Interpolate
                                    t={t}
                                    i18nKey={
                                      "jumperrwd.ebookHistoryQueryGroup." +
                                      listShowType +
                                      "TotalInfo"
                                    }
                                    parent={"div"}
                                    total={
                                      <span className="orange">{total}</span>
                                    }
                                  />
                                </a>
                              </li>
                              {queryGroupList &&
                                queryGroupList.map((item, i) => {
                                  let {
                                    dbID,
                                    hasLimit,
                                    sourceName,
                                    sourceEnName,
                                    count,
                                    remainCount,
                                  } = item;
                                  let showName = sourceName;
                                  if (language === "en") {
                                    showName = sourceEnName;
                                  }
                                  return (
                                    <>
                                      <li
                                        className={
                                          theDBID === dbID ? "here" : ""
                                        }>
                                        <a
                                          tabIndex="0"
                                          href="javascript:;"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.changeDBID(dbID);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.changeDBID(dbID);
                                            }
                                          }}>
                                          {showName}
                                        </a>
                                        <div className="borrow_list">
                                          {hasLimit ? (
                                            <>
                                              {remainCount !== 0 ? (
                                                <>
                                                  <Interpolate
                                                    t={t}
                                                    i18nKey={
                                                      "jumperrwd.ebookHistoryQueryGroup." +
                                                      listShowType +
                                                      "InfoHasLimit"
                                                    }
                                                    parent={"div"}
                                                    count={
                                                      <span className="orange">
                                                        {count}
                                                      </span>
                                                    }
                                                    remainCount={
                                                      <span className="green">
                                                        {remainCount}
                                                      </span>
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <Interpolate
                                                    t={t}
                                                    i18nKey={
                                                      "jumperrwd.ebookHistoryQueryGroup." +
                                                      listShowType +
                                                      "InfoExceedLimit"
                                                    }
                                                    parent={"div"}
                                                    count={
                                                      <span className="orange">
                                                        {count}
                                                      </span>
                                                    }
                                                    exceedLimit={
                                                      <span className="overtake">
                                                        {t(
                                                          "jumperrwd.ebookHistoryQueryGroup." +
                                                            listShowType +
                                                            "ExceedLimit"
                                                        )}
                                                      </span>
                                                    }
                                                  />
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <Interpolate
                                                t={t}
                                                i18nKey={
                                                  "jumperrwd.ebookHistoryQueryGroup." +
                                                  listShowType +
                                                  "Info"
                                                }
                                                parent={"div"}
                                                count={
                                                  <span className="orange">
                                                    {count}
                                                  </span>
                                                }
                                              />
                                            </>
                                          )}
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="left_info">
                            {ReactHtmlParser(
                              t(
                                "jumperrwd.ebookHistoryQueryGroup." +
                                  listShowType +
                                  "LeftInfo"
                              )
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                );
              }
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default EbookHistoryQueryGroupComp;
